@import '../node_modules/css-reset-and-normalize/scss/reset-and-normalize.scss';
@import '/src/fonts/fonts.scss';
@import '/src/styles/variables/colors.scss';

body {
  margin: 0;
  height: 100%;
  font-family: 'Gilroy', sans-serif;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

*:focus{
  outline: none;
}

button:focus{
  outline: none;
}

#root {
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.form-input{
  display: block;
  background: $input-background;
  color: $primary-text-color;
  height: 4.5rem;
  padding: 0 1rem;
  width: 28rem;
  border: none;
  border-radius: 5px;
  font-size: 2rem;
  margin-bottom: 1rem;
  width: 100%;
}

input[type='date'] {
  text-align: center;
  padding: 20px 0;
}