.privacy-checkbox {
    margin-top: 2rem;
    width: 100%;
    color: white;

    a{
        text-decoration: underline;
        color: white;
        margin: 0 .2rem;
    }
    label {
        font-size: 1rem;
        margin-top: 1rem;
    }

    .checkbox-wrapper {
        display: flex;
        align-items: center;
        label {
            margin-right: .4rem
        }
        .labels {
            display: flex;
            flex-direction: row;
            font-size: 1rem;
            margin-top: 1rem;
        }
    }
}