@import '../constants.scss';

.container {
  height: 100vh;
}

.layout {
  background-color: $primary-background-color;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px 0 0 25px;
}

.thank-you{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  margin: 3rem;
  color: $primary-text-color;
}

.not-found{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  margin: 3rem;
  color: $primary-text-color;
}

.admin {
  justify-content: flex-start;
  padding: 45px;
}

.language {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 1rem;
  label{
    color: $primary-text-color;
  }
  .input-select {
    margin: 0.42rem 0 1rem 0;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    background: rgba(100, 132, 119, 0.15);
    height: 2rem;
    padding: 0 0.625rem;
    
    &__control{
      height: 2rem;
      border: none;
    }

    &__value-container{
      height: 100%;
    }

    &__single-value{
      background: white;
    }
  }
}

@media only screen and (max-width: 700px) {
  .container {
    height: 100%;
  }
  .layout {
    width: 100%;
    border-radius: 15px 15px 0 0;
  }
  .thank-you{
    font-size: 3rem;
    margin: 2rem;
  }

  .not-found{
    font-size: 3rem;
    margin: 2rem;
  }

  .admin{
    padding: 0;
  }

  .language{
    top: 70px;
  }

}