// @use '../../fonts/fonts.scss';
@import '../constants';

.step-block {
    display: flex;
    flex-direction: column;
    line-height: 2rem;
    color: $primary-text-color;

    .step {
        color: $white-opaque;
        font-weight: 700;
        letter-spacing: 0.3rem;
    }

    .heading {
        display: inline-block;
        font-size: 2.25rem;
        font-weight: 300;
        margin-bottom: 1rem;
    }

    .sub-heading {
        font-weight: 300;
        line-height: 1.4rem;
        margin-bottom: 1rem;
    }
}

@media only screen and (max-width: 700px) {
    .step-block{
        align-self: flex-start;

        .step {
            font-size: 0.8rem;
        }
        .heading {
            font-size: 1.8rem;
            margin-bottom: 0.5rem;
        }
        .sub-heading {
            font-size: 0.8rem;
        }
    }
}