.sidemenu {
  position: relative;
  display: flex;
  width: 313px;
  height: 100vh;
  flex-direction: column;

  .sidebar-logo {
    padding: 34px 42px;
  }

  &-links {
    padding: 120px 0 30px 0;
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    .active {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 4px;
        height: 100%;
        background-color: #4e665c;
        border-radius: 5px;
        border: none;
        top: 0%;
      }
    }

    a {
      display: flex;
      font-size: 24px;
      color: #4e665c;
      text-decoration: none;
      padding: 18px 0;

      svg {
        margin: 0 48px;
      }
    }

    .logout-button{
      background: none;
      border: none;
      font-size: 1.5rem;
      text-align: left;
      cursor: pointer;
      color: #4E665C;

      svg{
        margin: 0 48px;
      }
    }
  }

  .mobileNavIcon{
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .sidemenu{
    width: 250px;

    &-links{
      a{
        font-size: 20px;

        svg{
          margin: 0 24px;
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .sidemenu{
    width: 100%;
    height: fit-content;
    flex-direction: row;
    justify-content: space-between;

    .mobileNavIcon{
      display: block;
      background: none;
      border: none;
      margin-right: 8px;
    }

    .sidebar-logo{
      padding: 0;
    }

    &-links{
      display: none;
    }
  }
}
