@import '../constants';


// class for both adult and children details form
.adult-children-details{
  width: 70%;
  max-width: 600px;
}


.adult-children-form {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 300;

  label {
    color: $primary-text-color;
    padding-bottom: 5px;
  }

  .input-select{
    margin: 0.42rem 0 1rem 0;
    font-size: 1rem;

    &__control{
      height: 3.75rem;
      background: $input-background;
      border: none;
      color: $primary-text-color;
    }

    &__value-container{
      height: 100%;
    }

    &__single-value{
      color: $primary-text-color;
    }
  }

  label {
    font-size: 1.5rem;
  }

  input {
    margin-top: 0.42rem;
    height: 60px;
    font-size: 1rem;
  }

  .row-container {
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;

    .save-add-children{
      background: rgba(255, 255, 255, 0.494);
      border: none;
      font-size: 1rem;
      text-transform: uppercase;
      color: $primary-text-color;
      border-radius: 5px;
      height: 60px;
      padding: 0 23px;
      cursor: pointer;
      font-weight: 600;
      letter-spacing: 0.2em;
    }
  }

  // input widths of adult and children details form
  &__first-name {
    width: 50%;
  }
  &__last-name {
    width: 50%;
  }
  &__gender {
    width: 40%;
  }
  &__email {
    width: 100%;
  }
  &__postal-code {
    width: 50%;
  }
  &__table-number {
    width: 50%;
  }

  //input widths of unique children details form
  &__dob{
    width: 60%;
  }
}

@media only screen and (max-width: 700px) {
  .adult-children-details{
    width: 80%;
  }

  .adult-children-form{
    label{
      font-size: 1rem;
    }
  
    .row-container{
      gap: 1rem;
    }

    &__gender {
      width: 50%;
    }
    &__email {
      width: 100%;
    }
  }
}

// children list displayed in the children page
.children{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.06rem 0;
  padding: 10px 15px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  
  .details-section{
    display: flex;
    align-items: center;
    gap: 0.93rem;

    .number{
      background: rgba(255, 255, 255, 0.7);
      color: #4E665C;
      padding: 13px 16px 9px 16px;
      font-size: 1.12rem;
      font-weight: 600;
      border-radius: 50%;
    }

    .details{
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      color: $primary-text-color;
      font-weight: 600;

      h5{
        font-size: 1.12rem;
      }

      p{
        font-size: 0.75rem;
      }
    }
  }
  .edit-icon{
    cursor: pointer;
  }
}

// edit children details modal
.children-modal{
  background: #FFF;
  width: 640px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 65px 60px 30px;
  font-weight: 300;

  header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.37rem;

    h1{
      font-size: 36px;
      color: #4E665C;
    }
  }

  .row-container{
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
  }

  label{
    font-size: 1.5rem;
  }

  input{
    border: none;
    border-radius: 5px;
    background: rgba(100, 132, 119, 0.15);
    height: 3.75rem;
    width: 100%;
    padding: 0 0.625rem;
    margin-top: 0.42rem;
  }

  &__first-name{
    width: 50%;
  }

  &__last-name{
    width: 50%;
  }

  &__gender{
    width: 40%;
  }

  &__dob{
    width: 60%;
  }

  .input-select{
    margin: 0.42rem 0 1rem 0;
    font-size: 1rem;

    &__control{
      height: 3.75rem;
      background: rgba(100, 132, 119, 0.15);
      border: none;
      color: $primary-text-color;
    }

    &__value-container{
      height: 100%;
    }

    &__single-value{
      color: #000;
    }
  }

  .actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;

    .actions-next-link{
      background: $button-background;
      color: $primary-text-color;
      height: 72px;
      width: 148px;
      font-size: 24px;
      border: none;
      border-radius: 100px;
      display: block;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      box-shadow: 5px 5px 15px 0px $box-shadow;
    }
  }
}

@media only screen and (max-width: 700px) {
  .children-modal{
    width: 100%;
    border-radius: 20px 20px 0 0;
    top: auto;
    left: auto;
    transform: none;
    bottom: 0;
    padding: 3rem 1.75rem;
    
    header{
      h1{
        font-size: 1.5rem;
      }
    }

    .row-container{
      flex-direction: column;
    }

    label{
      font-size: 1rem;
    }

    &__first-name{
      width: 100%;
    }
  
    &__last-name{
      width: 100%;
    }
  
    &__gender{
      width: 100%;
    }
  
    &__dob{
      width: 100%;
    }
  }
}