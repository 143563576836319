@import '../constants';

.layout-form{
  width: 50%;
  max-width: 460px;

  .radio-buttons {
    display: flex;
    flex-direction: column;
    color: $primary-text-color;
    font-size: 1.4rem;

    .receive-otp-label {
      margin-bottom: 1rem;
    }

    .otp-warning {
      margin-top: 1rem;
      font-size: 1rem;
    }
  }

  .actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    padding: 1.12rem 0;

    .secondary-button{
      background: transparent;
      border: none;
      color: $primary-text-color;
      font-size: 24px;
      cursor: pointer;
      height: fit-content;
    }
  
    .actions-next-link{
      background: $button-background;
      color: $primary-text-color;
      height: 72px;
      width: 148px;
      font-size: 24px;
      border: none;
      border-radius: 100px;
      display: block;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      box-shadow: 5px 5px 15px 0px $box-shadow;
    }
  }
}

@media only screen and (max-width: 700px) {
  .layout-form{
    width: 80%;
    
    input{
      font-size: 1.25rem;
    }

    .actions{
      .secondary-button{
        font-size: 18px;
      }

      .actions-next-link{
        height: 63px;
        width: 127px;
        font-size: 18px;
      }
    }
  }
}