@import '../constants';

.login-form {
  width: 45%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  label {
    display: inline-block;
    font-size: 2.25rem;
    font-weight: 300;
    margin-bottom: 0.42rem;
    color: #FFF;
  }

  // input {
  //   display: block;
  //   background: $input-background;
  //   color: $primary-text-color;
  //   height: 4.5rem;
  //   width: 28rem;
  //   border: none;
  //   border-radius: 5px;
  //   font-size: 2rem;
  //   margin-bottom: 1rem;
  //   width: 100%;
  // }

  #username{
    margin-bottom: 2rem;
  }

  .actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    padding: 34px 0;
    width: 100%;

    .actions-next-link{
      background: $button-background;
      color: $primary-text-color;
      height: 72px;
      width: 148px;
      font-size: 24px;
      border: none;
      border-radius: 100px;
      display: block;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      box-shadow: 5px 5px 15px 0px $box-shadow;
    }
  }
}

@media only screen and (max-width: 700px) {
  .login-form{
    width: 80%;

    label{
      font-size: 1.5rem;
    }
  }
}