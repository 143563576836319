.container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.sidebar {
  display: flex;
  width: 30%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 0 20px;

  &-logo {
    display: flex;
    flex-direction: column;
    
    img {
      max-width: 100%;
      height: auto;
      &:last-child {
        max-width: 90%;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .container {
    flex-direction: column;
  }
  .sidebar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 0;

    &-logo {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      margin: 1rem;
    }

    img {
      max-width: 40%;
      height: auto;
      &:last-child {
        max-width: 40%;
      }
    }
  }
}
