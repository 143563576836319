@import '../constants';

.restaurant-page-wrapper {
  width: 90%;
  color: #fff;
  height: 90%;

  .heading {
    font-size: 36px;
    padding: 30px 0;
  }

  .restaurants-card-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 42px;
    height: 95%;
    overflow-y: auto;

    .add-new-card {
      border: 1px dashed rgba(255, 255, 255, 0.5);
      border-radius: 5px;
      min-height: 220px;
      background: rgba(0, 0, 0, 0.08);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &-heading {
        padding: 15px 0;
        font-size: 28px;
        color: #fff;
      }
    }

    .restaurant-card {
      padding: 25px 30px;
      background: rgba(0, 0, 0, 0.08);
      border-radius: 5px;

      .restaurant-name {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          font-size: 1.37rem;
          font-weight: bold;
        }

        .options {
          display: flex;
          gap: 0.5rem;
          align-items: center;

          .card-option {
            cursor: pointer;
          }
        }
      }

      .restaurant-email {
        padding: 34px 0;

        h5 {
          font-size: 0.87rem;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          opacity: 0.8;
          padding-bottom: 0.4rem;
        }
      }

      .restaurant-url {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .url {
          width: 60%;
          word-break: break-word;

          h5 {
            text-transform: uppercase;
            letter-spacing: 0.2em;
            opacity: 0.8;
            padding-bottom: 0.4rem;
          }
        }

        .share-qr {
          cursor: pointer;
          display: flex;
          gap: 0.3rem;
          text-transform: uppercase;
        }
      }
    }
  }
}

// Admin Page - add restaurants modal styling
.add-restaurant-modal-wrapper {
  background-color: #fff;
  border-radius: 20px;
  padding: 3.43rem 0;
  width: 640px;
  height: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .add-restaurant-form {
    width: 100%;
    padding: 0 2.81rem;

    .add-resturant-modal-head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3.12rem;

      h2 {
        font-size: 2.25rem;
      }

      .modal-close-icon {
        height: fit-content;
        cursor: pointer;
      }
    }

    label {
      font-size: 1.5rem;
    }

    input {
      background: rgba(100, 132, 119, 0.15);
      border: none;
      width: 100%;
      padding: 0 0.625rem;
      border-radius: 5px;
      height: 3.75rem;
      margin-top: 0.42rem;
      margin-bottom: 1.5rem;
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
      padding: 34px 0;
      width: 100%;

      .actions-next-link {
        background: $button-background;
        color: $primary-text-color;
        height: 72px;
        width: 148px;
        font-size: 24px;
        border: none;
        border-radius: 100px;
        display: block;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        box-shadow: 5px 5px 15px 0px $box-shadow;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .restaurant-page-wrapper {
    .restaurants-card-wrapper {
      grid-template-columns: repeat(1, minmax(0, 1fr));

    }
  }
}

@media only screen and (max-width: 700px) {
  .restaurant-page-wrapper {
    .restaurants-card-wrapper {
      overflow-y: visible;
      padding-bottom: 30px;

      .add-new-card{
        &-heading{
          font-size: 1.5rem;
        }
      }

      .add-restaurant-modal-wrapper {
        top: auto;
        left: auto;
        bottom: 0;
        transform: none;
        width: 100%;
        border-radius: 20px 20px 0 0;

        padding: 30px 0 0;

        form {
          flex-direction: column-reverse;

          .qr-code-wrapper {
            width: 100%;
            padding: 0 25px;

            img {
              width: 250px;
              height: 250px;
            }

            svg {
              margin: 20px auto;
              width: 200px;
            }

            button[type='submit'] {
              background: $button-background;
              color: $primary-text-color;
              height: 72px;
              width: 148px;
              font-size: 24px;
              border: none;
              border-radius: 100px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              box-shadow: 5px 5px 15px 0px $box-shadow;
              margin-bottom: 1rem;
            }
          }

          .add-restaurant-form {
            width: 100%;
            padding: 0 25px;

            .add-resturant-modal-head {
              h2 {
                font-size: 24px;
              }
            }

            label {
              font-size: 1rem;
            }

            .actions {
              display: none;
            }
          }
        }
      }
    }
  }

  .add-restaurant-modal-wrapper {
    width: 100%;
    bottom: 0;
    top: auto;
    left: auto;
    transform: none;

    .add-restaurant-form{
      .add-resturant-modal-head{
        h2{
          font-size: 1.5rem;
        }
      }

      label{
        font-size: 1rem;
      }

      .actions{
        padding: 0;
      }
    }
  }
}