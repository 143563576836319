.delete-toast{
    border: none !important;
    
    .alert-box{
        display: flex;
        flex-direction: column;
        align-items: center;

        h2{
            font-size: 1.37rem;
            padding-bottom: 1rem;
        }

        .actions{
            display: flex;
            gap: 0.5rem;

            .btn{
                cursor: pointer;
                background-color: #4E665C;
                color: #FFFFFF;
                border: none;
                border-radius: 20px;
                padding: 10px 15px;
                min-width: 60px;
            }
        }
    }
}

// .delete-alert{
//     display: flex;
//     flex-direction: column;
//     border: none;
// }