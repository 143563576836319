@import '../constants';

.users-wrapper {
  width: 90%;
  color: $primary-text-color;
  height: 76%;

  .btn-grp {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .actions-next-link {
      background: $button-background;
      color: $primary-text-color;
      height: 72px;
      width: 148px;
      font-size: 24px;
      border: none;
      border-radius: 100px;
      display: block;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      box-shadow: 5px 5px 15px 0px $box-shadow;
    }
  }

  .layout-heading {
    font-size: 36px;
    margin: 30px 0;
  }

  .user-details-wrapper {
    height: 100%;
    overflow-y: auto;

    .user-details-row {
      background: rgba(0, 0, 0, 0.08);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 27px;
      border-radius: 5px;
      margin-bottom: 15px;

      .col{
        flex: 1;
      }
      .col-l{
        flex: 2;
      }
      .col-xl{
        flex: 3;
      }

      .heading{
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: rgba(256, 256, 256, 0.8);
      }

      div {
        padding: 0 10px;
        text-align: left;

        p {
          padding-top: 20px;
          font-weight: 500;
        }
      }
    }
  }
}

@media only screen and (max-width: 1537px) {
  .users-wrapper {
    height: 80%;

    .user-details-wrapper {
      .user-details-row {
        flex-direction: column;

        .resname {
          width: 100%;
        }

        div {
          padding: 10px 0;

          p {
            padding-top: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .users-wrapper{
    padding-bottom: 20px;

    .user-details-wrapper{
      height: fit-content;
      overflow-y: visible;
    }
  }
}