.home {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 0 20px;

  &-logo {
    display: flex;
    flex-direction: column;

    img {
      max-width: 100%;
      height: auto;
      &:last-child {
        max-width: 90%;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .home {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    &-logo {
      display: flex;
      flex-direction: column;

      img {
        max-width: 100%;
        height: auto;
        &:last-child {
          max-width: 90%;
        }
      }
    }
  }
}
