@import '../constants';

.select-children {
  max-width: 48rem;
  color: $primary-text-color;
  padding: 1rem;

  header {
    margin-bottom: 2rem;

    h1 {
      font-size: 2.25rem;
      margin-bottom: 1rem;
    }
    span{
      line-height: 1.4rem;
    }
  }

  .children-row-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;

    .children-container {
      width: 12rem;
      min-height: 8rem;
      padding: 1rem;
      background-color: #00000014;
      border-radius: 5px;

      .checkbox-input {
        margin-bottom: 0.92rem;
        background: rgba(255, 255, 255, 0.2);;
      }

      .children-name {
        font-weight: 500;
        font-size: 1.25rem;
      }
      .children-other-details {
        font-size: 1rem;
        font-weight: 300;
        margin-top: 0.42rem;
      }
    }
  }

  .add-new-children {
    border: 1px dashed rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    width: 12rem;
    min-height: 8rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    p{
      font-weight: 600;
      margin-top: 0.82rem;
    }
  }

  .actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    padding: 34px 0;
    width: 100%;

    .actions-next-link{
      background: $button-background;
      color: $primary-text-color;
      height: 72px;
      width: 148px;
      font-size: 24px;
      border: none;
      border-radius: 100px;
      display: block;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      box-shadow: 5px 5px 15px 0px $box-shadow;
    }
  }
}

@media only screen and (max-width: 700px) {
  .select-children {
    width: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    header {
      margin-bottom: 2rem;
      
      h1 {
        font-size: 2.25rem;
        margin-bottom: 1rem;
      }
      span {
        line-height: 1.4rem;
      }
    }

    .children-row-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      border-radius: 10px;

      .children-container {
        width: 9.76rem;
        padding: 1rem;
        background-color: #00000014;

        .checkbox-input {
          height: 2rem;
        }
        .children-name {
          font-weight: 500;
          font-size: 1rem;
          margin-bottom: 0.4rem;
        }
      }

      .add-new-children {
        width: 9.76rem;

        p{
          font-size: 0.87rem;
        }
      }
    }
  }
}
