.mobile-nav-menu {
  display: none;
}

@media only screen and (max-width: 700px) {
  .mobile-nav-menu {
    padding-bottom: 2.43rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    right: 0;
    bottom: 0;
    height: 100%;
    background: #fff;
    width: 21.37rem;

    .nav-menu-header{
      display: flex;
      justify-content: flex-end;
      padding: 0.6rem 0.9rem 2.43rem 1rem;
    }

    .logout-button{
      background: none;
      border: none;
      font-size: 1.5rem;
      text-align: left;
      cursor: pointer;
      color: #4E665C;

      svg{
        margin: 0 48px;
      }
    }

    a {
      display: flex;
      font-size: 1.5rem;
      color: #4e665c;
      text-decoration: none;
      padding: 1.12rem 0;

      svg {
        margin: 0 48px;
      }
    }

    .active {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 4px;
        height: 100%;
        background-color: #4e665c;
        border-radius: 5px;
        border: none;
        top: 0%;
      }
    }
  }
}
