@import '../constants';

.registered-user{
  width: 70%;
  max-width: 518px;

  h1{
    font-size: 2.25rem;
    margin-bottom: 2.8rem;
    color: $primary-text-color;

    span{
      font-weight: 600;
    }
  }

  label{
    font-size: 1.5rem;
    color: $primary-text-color;
  }
  
  input{
    height: 3.75rem;
    margin-top: 0.42rem;
    font-size: 1rem;
  }

  .input-select{
    margin: 0.42rem 0 1rem 0;
    font-size: 1rem;

    &__control{
      height: 3.75rem;
      background: $input-background;
      border: none;
      color: $primary-text-color;
    }

    &__value-container{
      height: 100%;
    }

    &__single-value{
      color: $primary-text-color;
    }
  }

  .adult-details{
    display: flex;
    justify-content: space-between;
    margin-top: 2.06rem;
    padding: 1.25rem;
    color: $primary-text-color;
    background: rgba(0, 0, 0, 0.08);
    border-radius: 5px;

    .adult-name-gender{
      font-size: 1.12rem;
      font-weight: 600;
      margin-bottom: 0.42rem;
    }

    .other-details{
      font-size: 0.75rem;
      margin-bottom: 0.42rem;
    }
  }

  .edit-icon{
    cursor: pointer;
  }
}


// Modal when user edits his/her info in registered process
.adult-modal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 4.06rem 3.75rem 1.87rem;
  border-radius: 20px;
  width: 40rem;

  header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.37rem;

    h1{
      font-size: 36px;
      color: #4E665C;
    }

    .icon-close{
      cursor: pointer;
    }
  }

  .row-container{
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
  }

  label{
    font-size: 1.5rem;
  }

  input{
    border: none;
    border-radius: 5px;
    background: rgba(100, 132, 119, 0.15);
    height: 3.75rem;
    width: 100%;
    padding: 0 0.625rem;
    margin-top: 0.42rem;
  }
  .input-select{
    margin: 0.42rem 0 1rem 0;
    font-size: 1rem;

    &__control{
      height: 3.75rem;
      background: rgba(100, 132, 119, 0.15);
      border: none;
      color: $primary-text-color;
    }

    &__value-container{
      height: 100%;
    }

    &__single-value{
      color: #000;
    }
  }

  &__gender{
    width: 40%;
  }

  &__postal-code{
    width: 60%;
  }
  
  &__email{
    width: 100%;
  }

  .actions{
    display: flex;
    flex-direction: row-reverse;

    .actions-next-link{
      background: $button-background;
      color: $primary-text-color;
      height: 72px;
      width: 148px;
      font-size: 24px;
      border: none;
      border-radius: 100px;
      display: block;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      box-shadow: 5px 5px 15px 0px $box-shadow;
    }
  }
}

@media only screen and (max-width: 700px) {
  .registered-user{
    width: 80%;
  }

  .adult-modal{
    top: auto;
    left: auto;
    bottom: 0;
    transform: none;
    width: 100%;
    padding: 3rem 1.75rem;

    header{
      h1{
        font-size: 1.5rem;
      }
    }
    label{
      font-size: 1rem;
    }

    .row-container{
      flex-direction: column;
      gap: 0;
    }

    &__last-name{
      margin-top: 0.72rem;
    }

    &__gender{
      width: 100%;
    }
    &__postal-code{
      width: 100%;
    }
    &__dob {
      width: 100%;
    }
  }
}