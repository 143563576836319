.qr-codes-page-wrapper {
  width: 90%;
  color: #fff;
  height: 85%;

  .heading {
    font-size: 36px;
    margin: 30px 0;
  }

  .codes-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;
    height: 100%;
    overflow-y: auto;

    .code-details {
      display: flex;
      background: rgba(0, 0, 0, 0.08);
      padding: 20px;
      border-radius: 5px;
      cursor: pointer;

      .qr-code {
        width: 160px;
        margin-right: 15px;
      }

      .restaurant-details {
        word-break: break-word;

        h2 {
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 15px;
        }

        h3 {
          font-size: 12px;
          text-transform: uppercase;
        }

        p {
          font-size: 14px;
          margin-bottom: 15px;
        }
      }
    }
  }
}

.admin-modal {
  height: fit-content;
  max-height: 730px;
  width: 530px;
  background-color: #fff;
  border: none;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 63px 36px;

  .modal-head {
    display: flex;
    flex-direction: row-reverse;

    .modal-close-icon {
      cursor: pointer;
      height: fit-content;
    }
  }

  .modal-content {
    padding: 2.43rem 2.68rem;
    display: grid;
    place-items: center;

    h2 {
      font-size: 36px;
      margin-bottom: 2rem;
    }

    svg {
      max-width: 300px;
    }
  }

  .save-code {
    background: #4E665C;
    ;
    color: #FFF;
    height: 4.5rem;
    width: 9.25rem;
    font-size: 1.5rem;
    border: none;
    border-radius: 100px;
    display: block;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1000px) {
  .qr-codes-page-wrapper {
    height: 80%;

    .codes-wrapper {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}

@media only screen and (max-width: 700px) {
  .qr-codes-page-wrapper{
    padding-bottom: 20px;

    .codes-wrapper{
      overflow-y: visible;
    }
  }

  .admin-modal {
    height: 600px;
    width: 100%;
    bottom: 0;
    top: auto;
    left: auto;
    transform: none;
    border-radius: 20px 20px 0 0;

    .modal-content {
      h2 {
        font-size: 24px;
      }
      svg{
        width: 100%;
      }
    }
  }
}